<template>
  <div id="user-list">
    <!-- app drawer -->
    <attribute-value-aside
      v-if="formReady && (attributeTypeFilter || resource)"
      v-model="isAddNewAttributeValueSidebarActive"
      :attribute-type-id="attributeTypeFilter"
      :resource="resource"
      @changed="loadAttributeValues(); isAddNewAttributeValueSidebarActive = false"
    ></attribute-value-aside>

    <!-- user total card -->
    <v-row class="mb-5">
      <v-col
        v-for="total in totalLocal"
        :key="total.total"
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.total }}
              </h2>
              <span>{{ total.title }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- list filters -->
    <v-card>
      <v-card-title>
        Search &amp; Filter
      </v-card-title>
      <v-row class="px-2 ma-0">
        <!-- search filter -->
        <v-col
          cols="12"
          sm="6"
          class="d-flex"
        >
          <v-text-field
            v-model="searchQuery"
            placeholder="Search"
            outlined
            hide-details
            dense
            class="user-search me-3 mb-4"
          >
          </v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="6"
        >
          <div class="d-flex justify-end flex-wrap">
            <template
              v-if="selectedRows.length"
            >
              <v-btn
                color="success"
                class="mb-4 me-3"
                :loading="loading"
                @click.stop="updateAllAttributeValues({ active: 1 })"
              >
                <span>Activate</span>
              </v-btn>
              <v-btn
                color="warning"
                class="mb-4 me-3"
                :loading="loading"
                @click.stop="updateAllAttributeValues({ active: 0 })"
              >
                <span>Deactive</span>
              </v-btn>
            </template>

            <v-select
              v-model="attributeTypeFilter"
              placeholder="Type"
              :items="attributeTypesOptions"
              clearable
              class="user-search mr-2"
              item-text="title"
              item-value="value"
              outlined
              dense
              hide-details
            ></v-select>

            <v-btn
              color="primary"
              class="mb-4 me-3"
              :loading="loading"
              :disabled="!attributeTypeFilter"
              @click.stop="addNewResoure"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon>
              <span>Add New Value</span>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="attributeValueListTable"
        :options.sync="options"
        :server-items-length="totalListTable"
        :loading="loading"
        show-select
      >
        <!-- ID -->
        <template #[`item.id`]="{item}">
          <span>{{ item.id }}</span>
        </template>

        <!-- Key -->
        <template #[`item.key`]="{item}">
          <span
            class="cursor-pointer"
            @click="setAttributeValue(item)"
          >
            {{ item.key }}
          </span>
        </template>

        <!-- AttributeValue -->
        <template #[`item.attributeValue`]="{item}">
          <span
            class="cursor-pointer"
            @click="setAttributeValue(item)"
          >
            {{ t(item.value) }}
          </span>
        </template>

        <!-- Active -->
        <template #[`item.status`]="{item}">
          <v-chip :color="item.active ? 'success' : ''">
            {{ item.active ? 'Active' : 'Inactive' }}
            </v-chispan>
          </v-chip>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                link
                @click="setAttributeValue(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencil }}
                  </v-icon>
                  <span>Update</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                link
                @click="deleteAttributeValue(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { destroyAttributeValue } from '@api/product'
import {
mdiAccountOutline,
mdiDeleteOutline,
mdiDotsVertical,
mdiExportVariant,
mdiFileDocumentOutline,
mdiPencil,
mdiPlus,
mdiSquareEditOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'

// sidebar
import { avatarText } from '@core/utils/filter'

import { useNotifyErrors } from '@/composables'
import { t } from '@/plugins/i18n'
import store from '@/store'
import AttributeValueAside from '../product-resource/AttributeValueAside.vue'

import useAttributeValueList from './useAttributeValueList'

export default {
  components: {
    AttributeValueAside,
  },
  setup() {
    store.dispatch('product/fetchAllAttributeTypes')

    const {
      attributeValueListTable,
      tableColumns,
      searchQuery,
      attributeTypeFilter,
      totalListTable,
      loading,
      options,
      totalLocal,
      selectedRows,
      attributeTypesOptions,

      loadAttributeValues,
      updateAllAttributeValues,
    } = useAttributeValueList()

    const isAddNewAttributeValueSidebarActive = ref(false)
    const formReady = ref(true)
    const resource = ref(false)

    const rootAttributeValueName = attributeValues => {
      const attributeValue = attributeValues.find(c => !c.parent_id)

      return attributeValue ? t(attributeValue.value) : ''
    }

    // const attributeValueegoriesOptions = commonStore.state.attributeValueegories.map(attributeValue => ({ title: t(attributeValue.value), value: attributeValue.id }))

    const deleteAttributeValue = attributeValue => destroyAttributeValue(attributeValue.id).then(loadAttributeValues).catch(useNotifyErrors)

    const setAttributeValue = attributeValue => {
      resource.value = attributeValue
      isAddNewAttributeValueSidebarActive.value = true
      formReady.value = false
      setTimeout(() => {
        formReady.value = true
      })
    }

    const addNewResoure = () => {
      resource.value = null
      formReady.value = false
      isAddNewAttributeValueSidebarActive.value = true
      setTimeout(() => {
        formReady.value = true
      })
    }

    return {
      attributeValueListTable,
      tableColumns,
      searchQuery,
      attributeTypeFilter,
      totalListTable,
      attributeTypesOptions,
      addNewResoure,

      // categoriesOptions,
      loading,
      options,
      totalLocal,
      isAddNewAttributeValueSidebarActive,
      formReady,
      resource,

      selectedRows,
      avatarText,
      loadAttributeValues,
      deleteAttributeValue,
      setAttributeValue,
      updateAllAttributeValues,
      rootAttributeValueName,

      t,

      // icons
      icons: {
        mdiPencil,
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
